<template>
  <div class="about">
    <div class="titles azjgs">
      <div>
        <span>角色名称：</span>
        <el-input placeholder="请输入角色名称" v-model="croleName" clearable></el-input>
      </div>
      <div>
        <span>状态：</span>
        <el-select v-model="cdeleted" placeholder="全部" clearable>
          <el-option label="启用" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewRole')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addRole')" type="warning" size="mini" icon="el-icon-plus" @click="adds(0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="remark" label="角色描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="deleted" label="状态">
          <template slot-scope="{ row }">
            <el-switch v-model="row.deleted" active-color="#13ce66" :active-value="0" :inactive-value="1" inactive-color="#ff4949" @change="chgSwitch(row)" :disabled="!$has('editRoleUseFlag')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createDt" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="updateDt" label="更新时间" width="160"></el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button v-if="$has('getRolePrivilege')" @click="jurisdictionVisibleBefore(scope.row)" type="warning" size="small" icon="el-icon-edit-outline">编辑角色权限</el-button>
            <el-button v-if="$has('editRole')" @click="adds(1, scope.row)" type="primary" size="small" icon="el-icon-edit-outline">编 辑</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC">
          <div class="newDialog">
            <div>
              <i class="ired">* </i>
              <span>角色名：</span>
              <el-input placeholder="请输入角色名" v-model="paramsObj.roleName" clearable></el-input>
            </div>
            <div>
              <span>备注：</span>
              <el-input placeholder="请输入备注" v-model="paramsObj.remark" clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="dialogsAdd">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑权限 -->
      <el-dialog title="编辑角色权限" :visible.sync="jurisdictionVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC" style="overflow-y: auto; height: 300px">
          <div class="dialogs azjgs addMod jurisdictionSty">
            <div style="position: absolute; top: 5%; left: 50%; font-size: 18px; transform: translate(-50%, -5%)">
              当前角色：<span style="color: #409eff; font-weight: bold">{{ jurisdictionTitle }}</span>
            </div>
            <el-tree :data="data" show-checkbox accordion node-key="id" :default-checked-keys="userPrivileges" @check="nodeClick" :props="defaultProps"> </el-tree>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button v-if="$has('editRolePrivilege')" type="primary" @click="jurisdictionAdd">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { roleQuery, roleSave, roleUpdate, roleChangeUseFlag, roleEditRolePrivilege, roleGetAllMenuAndRoleHasPrivilege } from '@/util/http'
import qs from 'qs'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      croleName: '', //角色名
      cdeleted: '', //是否已删除
      dialogTitle: '新增角色', //模态框标题
      dialogVisible: false, //模态框
      paramsObj: {}, //模态框字段
      type: '', //新增修改类型
      jurisdictionVisible: false, //角色模态框
      data: [], //树形数组
      defaultProps: {
        children: 'children',
        label: 'moduleName',
      },
      userPrivileges: [], //回显
      checkedKeys: [], //选中的节点
      jurisdictionTitle: '', //当前角色名称
      loadingText: {
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      },
      roleId: '',
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading(this.loadingText)
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        deleted: this.cdeleted,
        roleName: this.croleName,
      }
      await roleQuery(params)
        .then((data) => {
          if (data) {
            this.tableData = data.result.records
            this.totals = data.result.total
          }
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.croleName = ''
      this.cdeleted = ''
      this.searchs(1, 10)
    },
    // 模态框显示
    adds(type, row) {
      this.paramsObj = {}
      this.type = type
      this.dialogVisible = true
      if (type == 0) {
        this.dialogTitle = '新增角色'
      } else if (type == 1) {
        this.paramsObj = Object.assign({}, row)
        this.dialogTitle = '编辑角色'
      }
    },
    // 新增,修改
    async dialogsAdd() {
      if (!this.paramsObj.roleName) return this.$message.error('角色名称不能为空')
      const api = this.type == 0 ? roleSave : roleUpdate
      api(qs.stringify(this.paramsObj)).then((data) => {
        if (data != undefined) this.initData(data)
      })
    },
    // 取消
    cancles() {
      this.paramsObj = {}
      this.roleId = ''
      this.dialogVisible = false
      this.jurisdictionVisible = false
    },
    // 弹框清空重查
    initData(data) {
      if (data) this.$message.success(data.message)
      this.cancles()
      this.searchs(1, 10)
    },
    // 编辑权限前弹框
    async jurisdictionVisibleBefore(row) {
      // console.log(row);
      this.jurisdictionTitle = row.roleName
      this.roleId = row.id
      await roleGetAllMenuAndRoleHasPrivilege({ roleId: this.roleId }).then((data) => {
        this.data = data.result.menu
        this.userPrivileges = data.result.userPrivileges
      })
      this.jurisdictionVisible = true
    },
    // 编辑权限
    jurisdictionAdd() {
      let params = {
        privilegeIds: this.checkedKeys.toString(),
        roleId: this.roleId,
      }
      roleEditRolePrivilege(qs.stringify(params)).then((data) => {
        if (data != undefined) this.initData(data)
      })
    },
    // 获取选中的节点
    nodeClick(a, b) {
      this.checkedKeys = []
      for (var i = 0; i < b.checkedKeys.length; i++) {
        if (b.checkedKeys[i]) {
          this.checkedKeys.push(b.checkedKeys[i])
        }
      }
    },
    // 启用禁用
    chgSwitch(row) {
      const loading = this.$loading(this.loadingText)
      let params = {
        id: row.id,
        delFlag: row.deleted,
      }
      roleChangeUseFlag(params)
        .then((data) => {
          if (data != undefined) this.initData(data)
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
  },
  mounted() {
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.jurisdictionSty {
  width: 52%;
  height: 100%;
  align-items: flex-start !important;
}
.cont {
  width: 100%;
}
</style>
